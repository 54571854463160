import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"service"} />
      <Helmet>
        <title>Наші послуги | Eventful Horizons</title>
        <meta
          name={"description"}
          content={"Майстерність виготовлення, деталь за деталлю"}
        />
        <meta
          property={"og:title"}
          content={"Наші послуги | Eventful Horizons"}
        />
        <meta
          property={"og:description"}
          content={"Майстерність виготовлення, деталь за деталлю"}
        />
        <meta
          property={"og:image"}
          content={"https://ua.kexonovstart.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://ua.kexonovstart.com/img/839888.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://ua.kexonovstart.com/img/839888.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="90px 0 100px 0" quarkly-title="Price-17">
        <Text
          margin="0px 0px 20px 0px"
          font="normal 400 20px/1.5 --fontFamily-sans"
          color="#808890"
        >
          Давайте зробимо це!
        </Text>
        <Text
          margin="0px 50% 20px 0px"
          font="normal 900 48px/1.2 --fontFamily-serifGaramond"
          lg-margin="0px 0 20px 0px"
        >
          Опис: Майстерність виготовлення, деталь за деталлю Наші комплексні
          послуги
        </Text>
        <Text
          margin="0px 0px 70px 0px"
          font="normal 400 20px/1.5 --fontFamily-sans"
          color="--dark"
        >
          У Eventful Horizons ми прагнемо надати повний спектр послуг з
          організації заходів, щоб ваше свято пройшло безперешкодно і без
          стресу. Від інтимних зустрічей до масштабних постановок, наші послуги
          охоплюють всі аспекти планування заходів.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="34px 34px"
          md-grid-template-columns="1fr"
          md-grid-gap="35px 0"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 25px/1.2 --fontFamily-serifGaramond"
            >
              Вибір місця проведення заходу
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 400 20px/1.5 --fontFamily-sans"
              color="#808890"
            >
              Мальовничі декорації: Вибирайте з безлічі приголомшливих локацій,
              що ідеально підходять для будь-якої теми.
              <br />
              <br />
              Ексклюзивний доступ: Отримайте доступ до найбільш затребуваних
              майданчиків.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 25px/1.2 --fontFamily-serifGaramond"
            >
              Дизайн та декор подій
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 400 20px/1.5 --fontFamily-sans"
              color="#808890"
            >
              Тематичне чуття: Індивідуальні теми, що відображають ваше бачення
              та вражають ваших гостей.
              <br />
              <br />
              Декоративні елементи: Від вишуканих до вигадливих, ми втілюємо
              ваші ідеї в життя.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 25px/1.2 --fontFamily-serifGaramond"
            >
              Кейтеринг та кухня
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 400 20px/1.5 --fontFamily-sans"
              color="#808890"
            >
              Вишукані страви для гурманів: Насолоджуйтесь меню від кращих
              шеф-кухарів.
              <br />
              <br />
              Спеціальні меню: Індивідуальні варіанти, що відповідають дієтичним
              потребам та уподобанням.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 25px/1.2 --fontFamily-serifGaramond"
            >
              Розваги та аудіо-візуальні засоби
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 400 20px/1.5 --fontFamily-sans"
              color="#808890"
            >
              Найкращі виконавці: Від гуртів до діджеїв - обирайте найкращих.
              <br />
              <br />
              Передові технології: Найсучасніше звукове та візуальне обладнання
              для покращення будь-якої події.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 25px/1.2 --fontFamily-serifGaramond"
            >
              Фото та відеозйомка
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 400 20px/1.5 --fontFamily-sans"
              color="#808890"
            >
              Професійна зйомка: Талановиті фотографи та відеооператори
              зафіксують ваші моменти.
              <br />
              <br />
              Альбоми на замовлення: Індивідуальні варіанти для спогадів, які
              залишаються на все життя.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 600 25px/1.2 --fontFamily-serifGaramond"
            >
              Координація заходів
            </Text>
            <Text
              margin="0px 0px 30px 0px"
              font="normal 400 20px/1.5 --fontFamily-sans"
              color="#808890"
            >
              День координації: Переконайтеся, що ваш захід проходить
              безперебійно від початку до кінця.
              <br />
              <br />
              Комплексне планування: Від першої консультації до остаточного
              прощання.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-16"
      >
        <Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
        <Box
          width="100%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            height="auto"
            transform="translateY(0px)"
            padding="0px 0px 50% 0px"
            width="100%"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
          >
            <Image
              src="https://ua.kexonovstart.com/img/2.jpg"
              position="absolute"
              width="100%"
              bottom="0px"
              right={0}
              min-height="100%"
              object-fit="cover"
              display="block"
              top="auto"
              left={0}
            />
          </Box>
        </Box>
        <Box
          sm-padding="0px 0px 0px 0px"
          justify-content="space-between"
          width="38%"
          flex-direction="row"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          lg-order="1"
          lg-justify-content="center"
          display="flex"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
        >
          <Box display="flex" flex-direction="column" md-width="100%">
            <Text
              font="--headline2"
              lg-text-align="center"
              sm-font="--headline3"
              sm-margin="0px 0px 20px 0px"
              margin="0px 0px 0px 0px"
              color="--darkL2"
              md-text-align="left"
            >
              Дізнайтеся більше про нас
            </Text>
          </Box>
        </Box>
        <Box
          sm-padding="0px 0px 0px 0px"
          lg-order="1"
          display="flex"
          width="60%"
          flex-direction="row"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          justify-content="space-between"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-padding="0px 16px 16px 16px"
        >
          <Box display="flex" flex-direction="column">
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              lg-text-align="center"
              md-text-align="left"
            >
              Кожна послуга, яку ми пропонуємо, - це крок до досконалості вашого
              заходу. Щоб дізнатися, як ми можемо адаптувати наші послуги до
              ваших конкретних потреб, зв'яжіться з нами, не соромтеся
              звертатися. Незалежно від того, чи бажаєте ви обговорити свої
              плани по телефону, електронною поштою або при особистій зустрічі,
              ми надамо вам всю інформацію, необхідну для прийняття
              обґрунтованого рішення.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
